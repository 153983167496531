import { ReactElement, useEffect, useState, useRef } from 'react';
import {
  createSearchParams,
  useMatch,
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Typography, styled } from '@mui/material';
import Drawer from 'react-modern-drawer';
import classNames from 'classnames';

import { useLayout } from 'hooks/useLayout';
import { CompanyEntity, useProfile } from 'hooks/useProfile';
import { useViewport } from 'hooks/useViewport';
import { Button } from './Button';
import {
  getAuthWithExpire,
  getValueFromSession,
  setValueInSession,
} from 'utils/storage';
import { logout } from 'apis/auth';
import { getProfileVCF } from 'utils/vcf';

import { ReactComponent as CloseIcon } from 'images/icons/Close.svg';
import { ReactComponent as QCardLogo } from 'images/QCardLogo.svg';
import { dynamicPagesList } from 'pages/DynamicPages/pagelist';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { reactNativeUserAgent } from 'constants/userAgents';
import {
  checkQCardRNAppDevice,
  postMessageToQCardApp,
} from 'utils/qcardReactNativeHandlers';
import ShareModal from './ShareModal';
import { customLinkInNewTab } from 'utils/links';
import { convertImageUrlToDataURL } from 'utils/image';
import { useFeatureFlag } from 'configcat-react';
import { useAnalytics } from 'pages/AnalyticsPage/hooks/useAnalytics';
import { CustomButtonType } from 'apis/analytics';
import NavigateToMyCard from './NavigateToMyCard';
import { useNetworkStatus } from 'hooks/useNetworkStatus';
import { getOrientation } from 'utils/device';

export const StyledMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    display: 'block',
    marginRight: theme.spacing(2),
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.common.white,
    width: '100%',
    height: '100vh',
    position: 'fixed',
    zIndex: 100,
    left: 0,
    top: 0,
    overflow: 'auto',
    padding: '64px 24px',
    transitionProperty: 'all',
    transitionTimingFunction: 'linear',
    transitionDuration: '250ms',

    '@media screen and (min-width: 576px)': {
      padding: '64px 40px',
      width: 368,
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
    },
  },
  embeddedContainer: {
    paddingTop: 118,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    '& > div:not(:last-child)': {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    '& .logWrapper': {
      marginTop: '64px',
    },
  },
  title: {
    '&.MuiTypography-root': {
      borderBottomWidth: 1,
      borderBottomStyle: 'dashed',
      borderBottomColor: theme.palette.grey[50],
      paddingBottom: 8,
    },
  },
  required: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    textTransform: 'capitalize',
    color: '#545454',
  },
  link: {
    '&.MuiTypography-root': {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '24px',
      textTransform: 'none',
    },
  },
  requestInfoLink: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    textDecoration: 'none',
  },
  login: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '26px',
      textTransform: 'uppercase',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  embeddedCloseIcon: {
    top: 70,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 4,
    marginTop: 40,
  },
}));

interface SideMenuProps {
  open: boolean;
  openLogin: () => void;
  closeMenu: () => void;
}

export const SideMenu = ({
  open = false,
  openLogin,
  closeMenu,
}: SideMenuProps): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOnline } = useNetworkStatus();
  const {
    profile,
    repeatablePhoneNumbers,
    repeatableEmailAddress,
    repeatableWebsites,
    defaultTheme,
    profileImgUrl,
    companyEntity,
    detailFieldsOrder,
    isColleaguesUpdated,
  } = useProfile();
  const { isEmbedded, openMenu } = useLayout();
  const storageAuth = getAuthWithExpire();
  const csrfToken = storageAuth?.csrfToken;
  const { width: deviceWidth } = useViewport();
  const { isApple, isAndroid, isIpad, isWindows } = useDeviceDetect();
  const { value: isImpersonationFeatureEnabled } = useFeatureFlag(
    'isImpersonationFeatureEnabled',
    false
  );
  const [isScrollToTopOnImpersonation, setScrollToTopOnImpersonation] =
    useState<boolean>(false);
  const { impersonateUserAlias } = useProfile();
  const matchPathFirst = useMatch('/c/:username');
  const matchPathSecond = useMatch('/:username');
  const currentUser = storageAuth?.user;

  const [isOpenQRModal, setIsOpenQRModal] = useState<boolean>(false);
  const [codeQRModal, setCodeQRModal] = useState<string>('');
  const [downloadableData, setDownloadableData] = useState<string>('');
  const [base64image, setBase64image] = useState<string>('');
  const { title } = (companyEntity as CompanyEntity) ?? '';
  const [showImpersonateUserMenuItem, setShowImpersonateUserMenuItem] =
    useState<boolean>(false);
  const [showCreateAccountMenuItem, setShowCreateAccountMenuItem] =
    useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const handleBase64 = async (photo: string) => {
    let base64data = await convertImageUrlToDataURL(photo);
    setBase64image(base64data + '');
  };
  const { trackPageViews, trackClickEvent, trackCustomButtonTappedEvent } =
    useAnalytics(null);
  const { value: isCreateNewAccountForm } = useFeatureFlag(
    'createNewAccountForm',
    false
  );

  const updateOrientation = () => {
    const orientationIsLandscape = getOrientation().includes('landscape');
    if (orientationIsLandscape) {
      closeQRModal();
    }
  };

  useEffect(() => {
    updateOrientation();
    window.addEventListener('orientationchange', updateOrientation);
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
      window.removeEventListener('resize', updateOrientation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openMenu && isImpersonationFeatureEnabled) {
      getUserRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMenu, isImpersonationFeatureEnabled]);

  useEffect(() => {
    handleBase64(profileImgUrl + '');
  }, [profileImgUrl]);

  const openOfflineQRCode = (vcf: string, vcfNoPhoto: string) => {
    setCodeQRModal(vcfNoPhoto);
    setDownloadableData(vcf);
    setIsOpenQRModal(true);
    closeMenu();
  };

  const handleOfflineQRCode = () => {
    trackClickEvent(currentUser?.uid || '', 'offline-qr-code');
    if (profile) {
      const vcf = getProfileVCF({
        profile,
        photo: profileImgUrl,
        base64Photo: base64image,
        contacts: repeatablePhoneNumbers,
        emails: repeatableEmailAddress,
        websites: repeatableWebsites,
        isWindows,
        isADownload: true,
        companyName: title,
        detailFieldsOrder: detailFieldsOrder,
      });
      const vcfNoPhoto = getProfileVCF({
        profile,
        photo: profileImgUrl,
        base64Photo: base64image,
        contacts: repeatablePhoneNumbers,
        emails: repeatableEmailAddress,
        websites: repeatableWebsites,
        isWindows,
        isADownload: false,
        detailFieldsOrder: detailFieldsOrder,
        noNotes: true,
      });
      openOfflineQRCode(vcf, vcfNoPhoto);
    }
  };

  const redirectToHomeWithLoginModal = () => {
    closeMenu();
    if (matchPathFirst || matchPathSecond) {
      openLogin();
    } else {
      navigate({
        pathname: '/',
        search: createSearchParams({
          loginRequired: 'true',
        }).toString(),
      });
    }
  };

  const handleOnTheMetaverse = () => {
    let previousPath = location.pathname;
    if (impersonateUserAlias && !!impersonateUserAlias.view_user) {
      previousPath = impersonateUserAlias.view_user;
    } else if (currentUser) {
      previousPath = currentUser?.alias;
    }
    trackPageViews(currentUser?.uid || '', 'On The Metaverse');
    navigate(dynamicPagesList().OnTheMetaverse, {
      state: { previousPath },
    });
    closeMenu();
  };
  const handleAppleWalletInstructions = () => {
    let previousPath = location.pathname;
    if (impersonateUserAlias && !!impersonateUserAlias.view_user) {
      previousPath = impersonateUserAlias.view_user;
    } else if (currentUser) {
      previousPath = currentUser?.alias;
    }
    trackPageViews(currentUser?.uid || '', 'Apple Wallet');
    navigate(dynamicPagesList().AppleWalletInstructions, {
      state: { previousPath },
    });
    closeMenu();
  };
  const handleGoggleWalletInstructions = () => {
    let previousPath = location.pathname;
    if (impersonateUserAlias && !!impersonateUserAlias.view_user) {
      previousPath = impersonateUserAlias.view_user;
    } else if (currentUser) {
      previousPath = currentUser?.alias;
    }
    trackPageViews(currentUser?.uid || '', 'Google Wallet');
    navigate(dynamicPagesList().GoogleWalletInstructions, {
      state: { previousPath },
    });
    closeMenu();
  };
  const handleWidgetInstructions = () => {
    if (csrfToken) {
      let previousPath = location.pathname;
      if (impersonateUserAlias && !!impersonateUserAlias.view_user) {
        previousPath = impersonateUserAlias.view_user;
      } else if (currentUser) {
        previousPath = currentUser?.alias;
      }
      trackPageViews(currentUser?.uid || '', 'Widgets Instructions');
      navigate(dynamicPagesList().WidgetInstructions, {
        state: {
          previousPath,
        },
      });
      closeMenu();
    } else {
      redirectToHomeWithLoginModal();
      setValueInSession('beforelogin', dynamicPagesList().WidgetInstructions);
    }

    closeMenu();
  };

  const closeQRModal = () => {
    setIsOpenQRModal(false);
  };

  const handleAuth = () => {
    if (csrfToken) {
      trackCustomButtonTappedEvent(CustomButtonType.logOutButton);
      setTimeout(async () => {
        // Pushed it to Task queue
        const logoutToken = storageAuth?.logoutToken;
        setValueInSession('userImpersonation', null);
        setValueInSession('trackProfiles', null);
        await logout(logoutToken);
        closeMenu();
        if (window?.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'logout', value: {} })
          );
        }
        const lastVisitedProfileUrl = localStorage.getItem('lastVisitedProfileUrl');
        if (lastVisitedProfileUrl) {
          window.location.href = window.location.origin + lastVisitedProfileUrl;
        } else {
          window.location.href = window.location.origin;
        }
      }, 500);
    } else {
      trackCustomButtonTappedEvent(CustomButtonType.loginButton, {
        buttonSource: 'Side Menu',
      });
      openLogin();
    }
  };

  const handleShare = async () => {
    if (checkQCardRNAppDevice()) {
      postMessageToQCardApp('qrCodeModalShare', {
        type: 'url',
        value: `${profile?.metatag?.canonical_url}?vcf=true`,
      });
    } else {
      await navigator?.share({
        url: `${profile?.metatag?.canonical_url}?vcf=true`,
      });
    }
  };

  const isAdministratorOrNFCAdminUser = () => {
    const userRoles = getValueFromSession('userRoles');
    return (
      (userRoles?.includes('administrator') || userRoles?.includes('nfc_admin')) ??
      false
    );
  };

  const showImpersonateUser: boolean = isAdministratorOrNFCAdminUser();
  const getUserRoles = async () => {
    const userImpersonation = getValueFromSession('userImpersonation');
    if (impersonateUserAlias || userImpersonation) {
      setShowImpersonateUserMenuItem(false);
      setShowCreateAccountMenuItem(false);
      return;
    }
    if (!checkQCardRNAppDevice()) {
      setShowImpersonateUserMenuItem(showImpersonateUser);
      setShowCreateAccountMenuItem(showImpersonateUser);
    }
  };

  useEffect(() => {
    if (
      scrollContainerRef.current &&
      isImpersonationFeatureEnabled &&
      impersonateUserAlias &&
      !isScrollToTopOnImpersonation
    ) {
      scrollContainerRef.current.scrollTop = 0;
      setScrollToTopOnImpersonation(true);
    } else if (
      isScrollToTopOnImpersonation &&
      isImpersonationFeatureEnabled &&
      !impersonateUserAlias
    ) {
      setScrollToTopOnImpersonation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateUserAlias, isScrollToTopOnImpersonation]);

  const onRequestInfoClick = () => {
    trackCustomButtonTappedEvent(CustomButtonType.requestInfo);
  };

  return (
    <Drawer
      open={open}
      direction="left"
      size={deviceWidth < 576 ? deviceWidth : 368}
      enableOverlay={false}
    >
      <div
        className={classNames(
          classes.container,
          isEmbedded && classes.embeddedContainer
        )}
        ref={scrollContainerRef}
      >
        <CloseIcon
          cursor="pointer"
          className={classNames(
            classes.closeIcon,
            isEmbedded && classes.embeddedCloseIcon
          )}
          onClick={closeMenu}
        />
        <div className={classes.content}>
          {csrfToken && (
            <>
              <div>
                <Typography
                  className={classes.title}
                  fontSize={16}
                  lineHeight="24px"
                  color={defaultTheme?.ctaColor}
                  fontWeight={700}
                  textTransform="uppercase"
                >
                  My Profile
                </Typography>
                <Button
                  id="qcard-sidebar-edit-profile-btn"
                  variant="text"
                  title="Edit Profile"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={() => {
                    if (
                      navigator?.userAgent?.includes(reactNativeUserAgent) &&
                      window?.ReactNativeWebView
                    ) {
                      if (isOnline) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            type: 'OPEN_EXTERNAL_URL',
                            value:
                              window.location.origin +
                              '/?loginRequired=true&redirect=editcard ',
                          })
                        );
                      }
                      closeMenu();
                    } else {
                      if (csrfToken) {
                        navigate('/editcard');
                        closeMenu();
                        if (isColleaguesUpdated) {
                          //Reload the page if colleague is added/removed.
                          setTimeout(() => {
                            window.location.href =
                              window.location.origin + '/editcard';
                          }, 500);
                        }
                      } else {
                        redirectToHomeWithLoginModal();
                        setValueInSession('beforelogin', '/editcard');
                      }
                    }
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                <Button
                  id="qcard-sidebar-email-sign-btn"
                  variant="text"
                  title="Email Signature"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={async () => {
                    if (csrfToken) {
                      if (isOnline) {
                        trackPageViews(currentUser?.uid || '', 'Email Signature');
                        navigate('/generate-signature');
                      }
                      closeMenu();
                    } else {
                      redirectToHomeWithLoginModal();
                      setValueInSession('beforelogin', '/generate-signature');
                    }
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                <Button
                  id="qcard-sidebar-qr-btn"
                  variant="text"
                  title="Offline QR Code"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={handleOfflineQRCode}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                <Button
                  id="qcard-sidebar-analytics-btn"
                  variant="text"
                  title="Analytics"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={() => {
                    if (csrfToken) {
                      if (isOnline) {
                        trackPageViews(currentUser?.uid || '', 'Analytics');
                        navigate('/analytics');
                      }
                      closeMenu();
                    } else {
                      redirectToHomeWithLoginModal();
                      setValueInSession('beforelogin', '/analytics');
                    }
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                <Button
                  variant="text"
                  title="Help"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={async () => {
                    trackPageViews(currentUser?.uid || '', 'Help');
                    if (isOnline) {
                      customLinkInNewTab('https://www.qcardenterprise.com/help');
                    }
                    closeMenu();
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                <Button
                  variant="text"
                  title="Feedback"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={async () => {
                    trackPageViews(currentUser?.uid || '', 'Feedback');
                    if (isOnline) {
                      customLinkInNewTab('https://forms.gle/EDXC8Q81Pc5bFdmL6');
                    }
                    closeMenu();
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                {/* end */}
              </div>
              <div>
                <Typography
                  className={classes.title}
                  fontSize={16}
                  lineHeight="24px"
                  color={defaultTheme?.ctaColor}
                  fontWeight={700}
                  textTransform="uppercase"
                >
                  Mobile Features
                </Typography>
                {isApple && isIpad === false && (
                  <Button
                    id="qcard-sidebar-ios-wallet-btn"
                    variant="text"
                    title="Apple Wallet"
                    fontClassName={`${classes.link} qcard-sidebar-text`}
                    onClick={handleAppleWalletInstructions}
                    ctaColor={defaultTheme?.secondaryColor}
                  />
                )}
                {isAndroid && (
                  <Button
                    id="qcard-sidebar-google-btn"
                    variant="text"
                    title="Google Wallet"
                    fontClassName={`${classes.link} qcard-sidebar-text`}
                    onClick={handleGoggleWalletInstructions}
                    ctaColor={defaultTheme?.secondaryColor}
                  />
                )}
                <Button
                  id="qcard-sidebar-mobile-widget-btn"
                  variant="text"
                  title="Mobile Widgets"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={handleWidgetInstructions}
                  ctaColor={defaultTheme?.secondaryColor}
                />
              </div>
              <div>
                <Typography
                  className={classes.title}
                  fontSize={16}
                  lineHeight="24px"
                  color={defaultTheme?.ctaColor}
                  fontWeight={700}
                  textTransform="uppercase"
                >
                  Advanced Features
                </Typography>
                <Button
                  id="qcard-sidebar-company-directory-btn"
                  variant="text"
                  title="Find Colleagues"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={async () => {
                    if (csrfToken) {
                      trackPageViews(currentUser?.uid || '', 'Find Colleagues');
                      navigate('/company-directory');
                      closeMenu();
                    } else {
                      redirectToHomeWithLoginModal();
                      setValueInSession('beforelogin', '/company-directory');

                      setValueInSession('beforelogin', '/zoom-background');
                    }
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                {showImpersonateUserMenuItem && (
                  <Button
                    id="qcard-sidebar-impersonate-user-btn"
                    variant="text"
                    title="Impersonate User"
                    fontClassName={`${classes.link} qcard-sidebar-text`}
                    onClick={async () => {
                      if (csrfToken) {
                        navigate('/impersonate-user');
                        closeMenu();
                      } else {
                        redirectToHomeWithLoginModal();
                        setValueInSession('beforelogin', '/impersonate-user');
                      }
                    }}
                    ctaColor={defaultTheme?.secondaryColor}
                  />
                )}
                {showCreateAccountMenuItem && isCreateNewAccountForm && (
                  <Button
                    variant="text"
                    title="Create New Account"
                    fontClassName={`${classes.link} qcard-sidebar-text`}
                    onClick={async () => {
                      trackPageViews(currentUser?.uid || '', 'Create New Account');
                      navigate('/createNewAccount');
                      closeMenu();
                    }}
                    ctaColor={defaultTheme?.secondaryColor}
                  />
                )}
                <Button
                  id="qcard-sidebar-download-assets-btn"
                  variant="text"
                  title="Downloadable Assets"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={async () => {
                    if (csrfToken) {
                      trackPageViews(currentUser?.uid || '', 'Downloadable Assets');
                      navigate('/downloadable-assets');
                      closeMenu();
                    } else {
                      redirectToHomeWithLoginModal();
                      setValueInSession('beforelogin', '/downloadable-assets');
                    }
                  }}
                  ctaColor={defaultTheme?.secondaryColor}
                />
                <Button
                  id="qcard-sidebar-metaverse-btn"
                  variant="text"
                  title="On The Metaverse"
                  fontClassName={`${classes.link} qcard-sidebar-text`}
                  onClick={handleOnTheMetaverse}
                  ctaColor={defaultTheme?.secondaryColor}
                />
              </div>
            </>
          )}
          <div className="logWrapper">
            <Button
              variant="secondary"
              title={csrfToken ? 'Log Out' : 'Log In'}
              fontClassName={classes.login}
              onClick={handleAuth}
              ctaColor={defaultTheme?.ctaColor}
            />
            {!csrfToken && (
              <Typography style={{ textAlign: 'center' }}>
                Don’t have an account?{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://forms.gle/GukBZtzrupzi4xci7"
                  style={{ color: defaultTheme?.ctaColor }}
                  className={classes.requestInfoLink}
                  onClick={onRequestInfoClick}
                >
                  {' '}
                  Request info{' '}
                </Link>
              </Typography>
            )}
          </div>
          {impersonateUserAlias && (
            <NavigateToMyCard
              showLeftIcon={false}
              label="Navigate as Myself"
              navigateAsMyself={true}
            />
          )}
          <div className={classes.logoContainer}>
            <Typography
              fontSize={8}
              fontWeight={500}
              lineHeight="10px"
              textTransform="uppercase"
              color={(theme) => theme.palette.grey[100]}
            >
              POWERED BY
            </Typography>
            <QCardLogo width={72} height={32} />
          </div>
          <ShareModal
            open={isOpenQRModal}
            code={codeQRModal}
            downloadableData={downloadableData}
            handleClose={closeQRModal}
            handleShare={handleShare}
            title="Downloadable Contact Card (Works Offline)"
            vcf={true}
          />
        </div>
      </div>
    </Drawer>
  );
};
